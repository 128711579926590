.button-editar {
  background-color: #e0d64b !important;
}

.body-tareas {
  background: linear-gradient(120deg, #949379, #c97d3e);
}

span {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}

@media screen and (max-width: 576px) {
  span {
    font-size: 1rem;
  }

  i {
    font-size: 0.7rem;
  }
}
