.card-editar {
  position: relative;
  background: linear-gradient(45deg, #ffcc2f, #d35336, #543729, #cecece);
}

.title-editar-tarea {
  color: #fff;
  font-weight: 500;
}

.button-guardar-tarea-editada {
  border-radius: 100px !important;
  background-color: #dfc673 !important;
  font-weight: 700 !important;
}

.button-close-editar {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #941919 !important;
}

.icon-close-edit {
  color: #fff;
  font-size: 1rem;
}

.form-editar-tarea:focus {
  border-color: #c01f1f !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(187, 7, 7, 0.5) !important;
}

@media screen and (max-width: 576px) {
  .title-editar-tarea {
    font-size: 1.2rem;
  }
}
