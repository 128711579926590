.container-principal {
  height: 100vh;
}

.body-todo-list {
  max-height: 500px;
  overflow-y: auto;
}

.card-body {
  background-color: #eee7e7;
}

.title-todo-list {
  font-size: 2rem;
  font-weight: 700;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.button-borrar-todo {
  border-radius: 100px !important;
  background-color: #6e6997 !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.button-agregar-tarea {
  border-radius: 100px !important;
  background-color: #b8938d !important;
  font-weight: 700 !important;
}

.contador-tareas {
  font-size: 1.2rem;
  font-weight: 500;
}

.alert-validacion {
  background-color: #f3d6a8 !important;
}

@media screen and (max-width: 576px) {
  .title-todo-list {
    font-size: 1.4rem;
  }

  .contador-tareas {
    font-size: 1rem;
  }
}
